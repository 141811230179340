<template>
   <v-card>
      <v-card-title 
         class="py-1"
         style="background-color:#01579B;color:black;color:white;font-size:16px;">
         Chọn point
      </v-card-title>

      <v-card-text 
         class="px-2"
         style="max-height:400px;overflow-y:auto;">
         <v-row 
            v-for="(group, gIndex) in pointGroups" 
            :key="gIndex"
            class="ma-0">

            <v-col cols="12" class="pa-0 mt-4">
               <div class="px-2 py-0"
                  style="text-align:left;background-color:#DADADA;color:#222222;font-size:16px;font-weight:700;line-height:34px;min-height:34px;">
                  {{group.name}}
                  <div style="display:inline-block;vertical-align:top;float:right;">
                     <v-btn text class="text-none"
                        color="#222222" style="height:30px;"
                        @click="setGroupValue(group, false)">
                        <b>Xóa hết</b>
                     </v-btn>
                     <v-btn text class="text-none"
                        color="#01579B" style="height:30px;"
                        @click="setGroupValue(group, true)">
                        <b>Chọn hết</b>
                     </v-btn>
                  </div>
               </div>
            </v-col>
            <v-col xs="6" sm="6" md="3" lg="3" xl="2"
               :cols="$vuetify.breakpoint.mdAndUp?3:6"
               class="pa-0 pt-2"
               v-for="(point, pIndex) in group.points"
                  :key="pIndex">
               <v-checkbox
                  v-model="point.active"
                  :label="point.name"
                  hide-details single-line
                  class="ma-0"
                  ></v-checkbox>
            </v-col>
         </v-row>
      </v-card-text>

      <v-card-actions>
         <v-spacer/>
         <v-btn
            dark
            class="text-none"
            color="#01579B"
            @click="onClickDone">
            Xong
         </v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
export default {
   data () {
      return {
         pickedPoint: [],
         pointGroups: require('@/mkdata/points').pointGroups
      }
   },
   methods: {
      onClickDone () {
         this.pickedPoint = []
         this.pointGroups.forEach(g => {
            g.points.forEach(p => {
               if (p.active) {
                  this.pickedPoint.push(p)
               }
            })
         })
         this.$emit('onPointPicked', this.pickedPoint)
      },
      setGroupValue (group, value) {
         group.points.forEach(p => {
            p.active = value
         })
      }
   }
}
</script>

<style>
   
</style>